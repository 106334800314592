import mainImg from '../assets/images/banner.jpg';
import scrollImg from '../assets/images/scroll-img.jpg';
import phoneImg from '../assets/images/mobile-frame.png';
import downloadBtn from '../assets/images/download-btn.svg';
import googlePlayIcon from "../assets/images/playStore.svg"
import appleIcon from "../assets/images/appStore.svg"
import btn from '../assets/images/btn.svg';
import QRCode from 'qrcode.react';
import { Link } from 'react-router-dom';
import './LandingPage.css';

const LandingPageMob = () => {
    const link = 'https://apps.apple.com/pk/app/onthago/id6449786148'; 
  

    return (
        <>
            

            <div className='landing-page'>
                {/* <img src={mainImg} alt="onThaGo" className="min-img" style={{ height: "72vh" }} /> */}
                <img src={mainImg} alt="onThaGo" className="min-img" />
                <div className="centered">
                    {/* <h2 style={{ fontSize: '3.2rem', fontWeight: '800', marginBottom: '1.5rem !important', fontFamily: 'Dancing Script', fontStyle: 'italic' }} > SKIP THE LINE</h2> */}
                      {/* <h2 className='main-img-title-mob'  ><span>Skip</span> THE LINE</h2> */}
                      <a href={link} target='blank'> <img src={btn} alt="" width="235" style={{ cursor: 'pointer' }} /> </a>
                </div>
            </div>

            <div >
                <div className="hungry-section" style={{ height: '150px !important' }} >
                    <h1 className='mb-4' style={{ fontSize: '3.5rem' }}>Hungry?</h1>
                    <h5>With No Time To Stay In Line</h5>
                    <h5><b>Onthago</b> Helps You Skip The Line! </h5>
                </div>
            </div>

            <div>
                <img src={scrollImg} alt="onThaGo" style={{ width: '100%', objectFit: 'contain' }} />
            </div>

            <div style={{ marginTop: '3rem' }}>
                <img src={phoneImg} alt="" style={{ width: '60%', height: '50%', objectFit: 'scale-down' }} />
            </div>

            <div style={{ marginTop: '3rem' }}>
                <h1 style={{ color: '#D8483D', textAlign: 'center', fontWeight: '900', padding: '0 17px', letterSpacing: '-1px' }}>BECAUSE WE </h1>
                <h1 style={{ color: '#D8483D', textAlign: 'center', fontWeight: '900', padding: '0 17px', letterSpacing: '-1px' }}>DON'T STAY IN LINE</h1>
                <span>
                    <h5 style={{ textAlign: 'center', margin: '2rem 1rem', fontSize:'18px' }}>Folks linger in queues, standing, pondering and
                        thinking of what it might have been. We believe
                        there is a faster way. </h5>
                </span>
                    <div >
                                <h6 style={{ textAlign: 'center' }}>Download App</h6>
                                <div style={{display: "flex", justifyContent: "center"}}>
                                <button className='global-button-style' onClick={() => window.location.href = 'https://apps.apple.com/us/app/onthago/id6449786148'}>
                                    <img height="32" src={appleIcon} alt="" />
                                    <div style={{display: "flex", flexDirection: "column", alignItems: "baseline"}}>
                                    <span style={{fontSize: "12px"}}>Downnload on the</span>
                                    <span>App Store</span>
                                    </div>
                                </button>
                                <button className='global-button-style' onClick={() => window.location.href = 'https://play.google.com/store/apps/details?id=co.tierzen.onthago'}>
                                    <img height="32" src={googlePlayIcon} alt="" />
                                    <div style={{display: "flex", flexDirection: "column", alignItems: "baseline"}}>
                                    <span style={{fontSize: "12px"}}>GET IT ON</span>
                                    <span>Google Play</span>
                                    </div>
                                </button>
                                </div>
                            </div>
                <div style={{ margin: '2rem 0 4rem 0' }}>
                <QRCode value={link} />
                             <Link to={link} target="_blank" rel="noopener noreferrer">
                                 </Link>
                </div>
            </div>

            

        </>
    );
}

export default LandingPageMob;